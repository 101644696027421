import { siteConfig } from "../configuration/config";
import { Word } from "./flashcard";

export const authTarget = siteConfig.useTokenAuth ? "token-auth" : "auth0";

export const FLASHCARD_STORAGE_KEY = "flashcard-selected-words";
export const FLASHCARD_STOPPED_AT = "flashcard-stopped-at";

export const MAX_FLASHCARD_ITEMS = 100;
export const defaultSelectedWords: Word[] = [];
export const appSignInRedirect = "/authentication/signin";
